import { render, staticRenderFns } from "./hw-powered-widgets.html?vue&type=template&id=59136029&scoped=true&"
import script from "./hw-powered-widgets.js?vue&type=script&lang=js&"
export * from "./hw-powered-widgets.js?vue&type=script&lang=js&"
import style0 from "./hw-powered-widgets.scss?vue&type=style&index=0&id=59136029&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59136029",
  null
  
)

export default component.exports